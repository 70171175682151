import React, { useEffect, useState } from "react";
import NewTitle from "../../extra/Title";

import { ReactComponent as VideoIcon } from "../../../assets/icons/VideoIcon.svg";
import { ReactComponent as UserTotalIcon } from "../../../assets/icons/UserSideBarIcon.svg";
import { ReactComponent as TotalChannelIcon } from "../../../assets/icons/ChannelIcon.svg";
import { ReactComponent as TotalShortsIcon } from "../../../assets/icons/ShortIcon.svg";
import Pagination from "../../extra/Pagination";
import Table from "../../extra/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMetricsCount,
  getChannelMetrics,
  getShortsMetrics,
  getUserMetrics,
  getVideoMetrics,
} from "../../store/metrics/metric.action";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const MetricsAnalytic = () => {
  const {
    videoMetrics,
    totalVideo,
    shortsMetrics,
    totalShorts,
    channelMetrics,
    totalChannel,
    userMetrics,
    totalUser,
    dashboardCount,
  } = useSelector((state) => state.metrics);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("All");
  const [endDate, setEndDate] = useState("All");

  const [data, setData] = useState([]);
  const [shortsData, setShortsData] = useState([]);
  const [channelData, setChannelData] = useState([]);
  const [userData, setUserData] = useState([]);

  const [type, setType] = useState(1);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [commentType, setCommentType] = useState();
  const [visibilityType, setVisibilityType] = useState();
  const [scheduleType, setScheduleType] = useState();

  useEffect(() => {
    dispatch(getVideoMetrics(startDate, endDate, page, size, "All", "All"));
    dispatch(getShortsMetrics(startDate, endDate, page, size, "All", "All"));
    dispatch(getChannelMetrics(startDate, endDate, page, size));
    dispatch(getUserMetrics(startDate, endDate, page, size));
    dispatch(getAllMetricsCount(startDate, endDate));
  }, [startDate, endDate, page, size]);

  useEffect(() => {
    setData(videoMetrics);
  }, [videoMetrics]);

  useEffect(() => {
    setShortsData(shortsMetrics);
  }, [shortsMetrics]);

  useEffect(() => {
    setChannelData(channelMetrics);
  }, [channelMetrics]);

  useEffect(() => {
    setUserData(userMetrics);
  }, [userMetrics]);

  const handleRowsPerPage = (value) => {
    setPage(1);
    setSize(value);
  };
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const videoMapData = [
    {
      Header: "NO",
      body: "no",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(page - 1) * size + parseInt(index) + 1}
        </span>
      ),
    },

    {
      Header: "VIDEO ID",
      body: "uniqueVideoId",
      Cell: ({ row }) => (
        <span className="cursorPointer">{row?.uniqueVideoId}</span>
      ),
      sorting: { type: "client" },
    },
    {
      Header: "CHANNEL NAME",
      body: "channelName",
      Cell: ({ row }) => (
        <span className="cursorPointer">{row?.channelName}</span>
      ),
      sorting: { type: "client" },
    },
    {
      Header: "LOCATION",
      body: "location",
      Cell: ({ row }) => <span className="cursorPointer">{row?.location}</span>,
      sorting: { type: "client" },
    },
    {
      Header: "DURATION (Minutes)",
      body: "videoTime",
      Cell: ({ row }) => {
        const time = row?.videoTime / 6000;
        return <span className="cursorPointer">{time?.toFixed(2)}</span>;
      },
      sorting: { type: "client" },
    },
    {
      Header: "LIKES",
      body: "like",
      Cell: ({ row }) => (
        <span className="cursorPointer">{row?.totalLikes}</span>
      ),
      sorting: { type: "client" },
    },
    {
      Header: "COMMENTS",
      body: "totalComments",
      Cell: ({ row }) => (
        <span className="cursorPointer">{row?.totalComments}</span>
      ),
      sorting: { type: "client" },
    },
    {
      Header: "VIEWS",
      body: "views",
      Cell: ({ row }) => <span className="cursorPointer">{row?.views}</span>,
      sorting: { type: "client" },
    },
    {
      Header: "REPORT",
      body: "totalReports",
      Cell: ({ row }) => (
        <span className="cursorPointer">{row?.totalReports}</span>
      ),
      sorting: { type: "client" },
    },
  ];

  const channelMapData = [
    {
      Header: "NO",
      body: "no",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(page - 1) * size + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: "CHANNEL NAME",
      body: "channelName",
      Cell: ({ row }) => <span className="cursorPointer">{row?.fullName}</span>,
      sorting: { type: "client" },
    },
    {
      Header: "LOCATION",
      body: "country",
      Cell: ({ row }) => <span className="cursorPointer">{row?.country}</span>,
      sorting: { type: "client" },
    },
    {
      Header: "SUBSCRIBER",
      body: "subscriber",
      Cell: ({ row }) => (
        <span className="cursorPointer">{row?.totalSubscribers}</span>
      ),
      sorting: { type: "client" },
    },
    {
      Header: "TOTAL SHORTS",
      body: "totalShorts",
      Cell: ({ row }) => (
        <span className="cursorPointer">{row?.totalShorts}</span>
      ),
      sorting: { type: "client" },
    },
    {
      Header: "TOTAL VIDEOS",
      body: "totalVideos",
      Cell: ({ row }) => (
        <span className="cursorPointer">{row?.totalVideos}</span>
      ),
      sorting: { type: "client" },
    },
    {
      Header: "TOTAL LIKES",
      body: "totalLikes",
      Cell: ({ row }) => (
        <span className="cursorPointer">{row?.totalLikes}</span>
      ),
      sorting: { type: "client" },
    },
    {
      Header: "TOTAL COMMENTS",
      body: "totalComments",
      Cell: ({ row }) => (
        <span className="cursorPointer">{row?.totalComments}</span>
      ),
      sorting: { type: "client" },
    },
  ];

  const userMapData = [
    {
      Header: "NO",
      body: "no",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(page - 1) * size + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: "USER NAME",
      body: "fullName",
      Cell: ({ row }) => <span className="cursorPointer">{row?.fullName}</span>,
      sorting: { type: "client" },
    },
    {
      Header: "LOCATION",
      body: "country",
      Cell: ({ row }) => <span className="cursorPointer">{row?.country}</span>,
      sorting: { type: "client" },
    },
    {
      Header: "LIVE DURATION",
      body: "totalDuration",
      Cell: ({ row }) => (
        <span className="cursorPointer">{row?.totalDuration}</span>
      ),
      sorting: { type: "client" },
    },
    {
      Header: "TOTAL SHORTS",
      body: "totalShorts",
      Cell: ({ row }) => (
        <span className="cursorPointer">{row?.totalShorts}</span>
      ),
      sorting: { type: "client" },
    },
    {
      Header: "TOTAL VIDEOS",
      body: "totalVideos",
      Cell: ({ row }) => (
        <span className="cursorPointer">{row?.totalVideos}</span>
      ),
      sorting: { type: "client" },
    },

    {
      Header: "VIP",
      body: "isPremiumPlan",
      Cell: ({ row }) => (
        <span className="cursorPointer">
          {row?.isPremiumPlan ? "Yes" : "No"}
        </span>
      ),
      sorting: { type: "client" },
    },
  ];

  return (
    <>
      <div className="dashboard " style={{ padding: "15px" }}>
        <div className="dashboardHeader primeHeader mb-3 p-0">
          <NewTitle
            dayAnalyticsShow={true}
            titleShow={true}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            startDate={startDate}
            endDate={endDate}
            name={`Metrics`}
          />
        </div>
        <div className="dashBoardMain px-4 pt-2">
          <div className="row dashboard-count-box">
            <div
              className="  adminProfileBox px-2   col-lg-3 col-md-6 col-sm-12 cursor"
              onClick={() => setType(1)}
            >
              <div className="dashBoxData bg-white">
                <div className="icon icon-sm icon-shape-small  text-center border-radius-xl my-auto icon-data1">
                  <UserTotalIcon />
                </div>
                <div className="dashBox-text">
                  <h5 className="text-center">Total User</h5>
                  <h6 className="text-center pt-3  fw-bold">
                    {" "}
                    {dashboardCount?.totalUsers
                      ? dashboardCount?.totalUsers
                      : "0"}
                  </h6>
                </div>
              </div>
            </div>
            <div
              className="  adminProfileBox px-2   col-lg-3 col-md-6 col-sm-12 cursor"
              onClick={() => setType(2)}
            >
              <div className="dashBoxData bg-white">
                <div className="icon icon-sm icon-shape-small  text-center border-radius-xl my-auto icon-data1">
                  <TotalChannelIcon />
                </div>
                <div className="dashBox-text">
                  <h5 className="text-center">Total Channel</h5>
                  <h6 className="text-center pt-3  fw-bold">
                    {" "}
                    {dashboardCount?.totalChannels
                      ? dashboardCount?.totalChannels
                      : "0"}
                  </h6>
                </div>
              </div>
            </div>
            <div
              className="  adminProfileBox px-2  col-lg-3 col-md-6 col-sm-12  cursor"
              onClick={() => setType(3)}
            >
              <div className="dashBoxData bg-white">
                <div className="icon icon-sm icon-shape-small  text-center border-radius-xl my-auto icon-data1">
                  <VideoIcon />
                </div>
                <div className="dashBox-text">
                  <h5 className="text-center">Total Video</h5>
                  <h6 className="text-center pt-3  fw-bold">
                    {" "}
                    {dashboardCount?.totalVideos
                      ? dashboardCount?.totalVideos
                      : "0"}
                  </h6>
                </div>
              </div>
            </div>

            <div
              className="  adminProfileBox px-2  col-lg-3 col-md-6 col-sm-12 cursor"
              onClick={() => setType(4)}
            >
              <div className="dashBoxData bg-white">
                <div className="icon icon-sm icon-shape-small  text-center border-radius-xl my-auto icon-data1">
                  <TotalShortsIcon />
                </div>
                <div className="dashBox-text">
                  <h5 className="text-center">Total Shorts</h5>
                  <h6 className="text-center pt-3  fw-bold">
                    {" "}
                    {dashboardCount?.totalShorts
                      ? dashboardCount?.totalShorts
                      : "0"}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="table-content">
            {type === 1 && (
              <>
                <Table
                  data={userData}
                  mapData={userMapData}
                  serverPerPage={size}
                  serverPage={page}
                  type={"server"}
                />
                <div className="mt-3">
                  <Pagination
                    type={"server"}
                    activePage={page}
                    rowsPerPage={size}
                    userTotal={totalUser}
                    setPage={setPage}
                    handleRowsPerPage={handleRowsPerPage}
                    handlePageChange={handlePageChange}
                  />
                </div>
              </>
            )}

            {type === 2 && (
              <>
                <Table
                  data={channelData}
                  mapData={channelMapData}
                  serverPerPage={size}
                  serverPage={page}
                  type={"server"}
                />
                <div className="mt-3">
                  <Pagination
                    type={"server"}
                    activePage={page}
                    rowsPerPage={size}
                    userTotal={totalChannel}
                    setPage={setPage}
                    handleRowsPerPage={handleRowsPerPage}
                    handlePageChange={handlePageChange}
                  />
                </div>
              </>
            )}
            {type === 3 && (
              <>
                <div className="my-4 d-flex justify-content-end align-items-center">
                  <div className="metrics pagination-submit">
                    <FormControl
                      variant="filled"
                      fullWidth
                      className="mx-2"
                      style={{ width: "140px", height: "50px" }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        Comment Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={commentType}
                        label="Action"
                        onChange={(e) => {
                          setCommentType(e.target.value);
                          dispatch(
                            getVideoMetrics(
                              startDate,
                              endDate,
                              page,
                              size,
                              "commentType",
                              e.target.value
                            )
                          );
                        }}
                      >
                        <MenuItem value="All" key="All">
                          All
                        </MenuItem>
                        <MenuItem value={1} key={1}>
                          Allow
                        </MenuItem>
                        <MenuItem value={2} key={2}>
                          Disable
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="filled"
                      className="mx-2"
                      style={{ width: "140px", height: "50px" }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        Visibility Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={visibilityType}
                        label="Action"
                        onChange={(e) => {
                          setVisibilityType(e.target.value);
                          dispatch(
                            getVideoMetrics(
                              startDate,
                              endDate,
                              page,
                              size,
                              "visibilityType",
                              e.target.value
                            )
                          );
                        }}
                      >
                        <MenuItem value="All" key="All">
                          All
                        </MenuItem>
                        <MenuItem value={1} key={1}>
                          Public
                        </MenuItem>
                        <MenuItem value={2} key={2}>
                          Private
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="filled"
                      className="mx-2"
                      style={{ width: "140px", height: "50px" }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        Schedule Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={scheduleType}
                        label="Action"
                        onChange={(e) => {
                          setScheduleType(e.target.value);
                          dispatch(
                            getVideoMetrics(
                              startDate,
                              endDate,
                              page,
                              size,
                              "scheduleType",
                              e.target.value
                            )
                          );
                        }}
                      >
                        <MenuItem value="All" key="All">
                          All
                        </MenuItem>
                        <MenuItem value={1} key={1}>
                          Scheduled
                        </MenuItem>
                        <MenuItem value={2} key={2}>
                          Not Scheduled
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <Table
                  data={data}
                  mapData={videoMapData}
                  serverPerPage={size}
                  serverPage={page}
                  type={"server"}
                />
                <div className="mt-3">
                  <Pagination
                    type={"server"}
                    activePage={page}
                    rowsPerPage={size}
                    userTotal={totalVideo}
                    setPage={setPage}
                    handleRowsPerPage={handleRowsPerPage}
                    handlePageChange={handlePageChange}
                  />
                </div>
              </>
            )}

            {type === 4 && (
              <>
                <div className="my-4 d-flex justify-content-end align-items-center">
                  <div className="metrics pagination-submit">
                    <FormControl
                      variant="filled"
                      className="mx-2"
                      style={{ width: "140px", height: "50px" }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        Comment Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={commentType}
                        label="Action"
                        onChange={(e) => {
                          setCommentType(e.target.value);
                          dispatch(
                            getShortsMetrics(
                              startDate,
                              endDate,
                              page,
                              size,
                              "commentType",
                              e.target.value
                            )
                          );
                        }}
                      >
                        <MenuItem value="All" key="All">
                          All
                        </MenuItem>
                        <MenuItem value={1} key={1}>
                          Allow
                        </MenuItem>
                        <MenuItem value={2} key={2}>
                          Disable
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="filled"
                      className="mx-2"
                      style={{ width: "140px", height: "50px" }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        Visibility Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={visibilityType}
                        label="Action"
                        onChange={(e) => {
                          setVisibilityType(e.target.value);
                          dispatch(
                            getShortsMetrics(
                              startDate,
                              endDate,
                              page,
                              size,
                              "visibilityType",
                              e.target.value
                            )
                          );
                        }}
                      >
                        <MenuItem value="All" key="All">
                          All
                        </MenuItem>
                        <MenuItem value={1} key={1}>
                          Public
                        </MenuItem>
                        <MenuItem value={2} key={2}>
                          Private
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="filled"
                      className="mx-2"
                      style={{ width: "140px", height: "50px" }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        Schedule Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={scheduleType}
                        label="Action"
                        onChange={(e) => {
                          setScheduleType(e.target.value);
                          dispatch(
                            getShortsMetrics(
                              startDate,
                              endDate,
                              page,
                              size,
                              "scheduleType",
                              e.target.value
                            )
                          );
                        }}
                      >
                        <MenuItem value="All" key="All">
                          All
                        </MenuItem>
                        <MenuItem value={1} key={1}>
                          Scheduled
                        </MenuItem>
                        <MenuItem value={2} key={2}>
                          Not Scheduled
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <Table
                  data={shortsData}
                  mapData={videoMapData}
                  serverPerPage={size}
                  serverPage={page}
                  type={"server"}
                />
                <div className="mt-3">
                  <Pagination
                    type={"server"}
                    activePage={page}
                    rowsPerPage={size}
                    userTotal={totalShorts}
                    setPage={setPage}
                    handleRowsPerPage={handleRowsPerPage}
                    handlePageChange={handlePageChange}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MetricsAnalytic;
