import React, { useEffect, useState } from "react";
import $ from "jquery";
import AddIcon from "@mui/icons-material/Add";
import Searching from "../extra/Searching";
import Button from "../extra/Button";
import Table from "../extra/Table";
import Pagination from "../extra/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { warning } from "../../util/Alert";
import { ReactComponent as EditIcon } from "../../assets/icons/EditBtn.svg";
import { OPEN_DIALOGUE } from "../store/dialogue/dialogue.type";
import {
  getVideoCategory,
  videoCategoryActiveOrNot,
} from "../store/video/video.action";
import VideoCategoryAdd from "../dialogue/VideoCategoryAdd";
import { covertURl } from "../../util/AwsFunction";
import ToggleSwitch from "../extra/ToggleSwitch";
import noImageFound from "../../assets/images/noimage.png";
import NewTitle from "../extra/Title";

const VideoCategory = () => {
  const dispatch = useDispatch();
  const [checkBox, setCheckBox] = useState();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [verificationRequests, setVerificationRequests] = useState();
  const [actionPagination, setActionPagination] = useState("delete");
  const [selectCheckData, setSelectCheckData] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [search, setSearch] = useState();

  const [data, setData] = useState([]);
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const [showURLs, setShowURLs] = useState([]);
  const { videoCategoryData } = useSelector((state) => state.video);

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", noImageFound);
    });
  });
  const handleSelectCheckData = (e, row) => {
    const checked = e.target.checked;
    if (checked) {
      setSelectCheckData((prevSelectedRows) => [...prevSelectedRows, row]);
    } else {
      setSelectCheckData((prevSelectedRows) =>
        prevSelectedRows.filter((selectedRow) => selectedRow._id !== row._id)
      );
    }
  };
  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAllChecked(checked);
    if (checked) {
      setSelectCheckData([...data]);
    } else {
      setSelectCheckData([]);
    }
  };

  const paginationSubmitButton = () => {
    const selectCheckDataGetId = selectCheckData?.map((item) => item?._id);
    if (actionPagination === "delete") {
      const data = warning();
      data
        .then((res) => {
          if (res) {
            const yes = res.isConfirmed;
            if (yes) {
              // props.deleteSoundCategory(selectCheckDataGetId);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    fetchData();
  }, [data]);

  const fetchData = async () => {
    if (!data || data.length === 0) {
      // Handle case when data is undefined or empty
      return;
    }

    const urls = await Promise.all(
      data.map(async (item) => {
        const fileNameWithExtension = item?.image?.split("/").pop();
        const { imageURL } = await covertURl(
          "videoCategoryImage/" + fileNameWithExtension
        );

        return imageURL;
      })
    );
    setShowURLs(urls);
  };

  const handleOpenNew = (type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type: type,
    };
    sessionStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleEdit = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type: type,
      dialogueData: row,
    };
    sessionStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };

  const soundCategoryMapData = [
    {
      Header: "NO",
      body: "no",
      Cell: ({ index }) => (
        <span className="  text-nowrap">{(page - 1) * size + index + 1}</span>
      ),
    },
    {
      Header: "Image",
      body: "image",
      Cell: ({ row, index }) => (
        <img src={showURLs[index]} width="40px" height="40px" />
      ),
    },
    {
      Header: "CATEGORY NAME",
      body: "name",
      Cell: ({ row }) => <span className="text-capitalize">{row?.name}</span>,
    },
    {
      Header: "ACTIVE TOGGLE",
      body: "isActive",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row?.isActive}
          onChange={() => handleIsActiveToggle(row)}
        />
      ),
    },
    {
      Header: "ACTION",
      body: "action",
      Cell: ({ row }) => (
        <div className="action-button">
          <Button
            btnIcon={<EditIcon />}
            onClick={() => handleEdit(row, "addCategory")}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getVideoCategory());
  }, [dispatch]);

  useEffect(() => {
    setData(videoCategoryData);
  }, [videoCategoryData]);

  const handleIsActiveToggle = (row) => {
    const id = row?._id;
    const data = row?.isActive === false ? true : false;
    dispatch(videoCategoryActiveOrNot(id, data));
  };
  return (
    <div className="userPage videoPage">
      <div className="dashboardHeader primeHeader mb-3 p-0">
        <NewTitle
          dayAnalyticsShow={false}
          name={`Video & Shorts Category`}
          titleShow={true}
        />
      </div>
      <div className="user-table">
        {dialogueType == "addCategory" && <VideoCategoryAdd />}
        <div className="user-table-top">
          <div className="row align-items-start">
            <div className="col-6">
             
            </div>
            <div
              className="col-6 new-fake-btn d-flex justify-content-end"
              style={{ marginTop: "5px" }}
            >
              <Button
                btnIcon={<AddIcon />}
                btnName={"New"}
                newClass={"rounded"}
                onClick={() => handleOpenNew("addCategory")}
              />
            </div>
            {/* <Searching
              placeholder={"Search..."}
              data={videoCategoryData}
              type={"client"}
              label={"Search for Keyword, Category Name"}
              setData={setData}
              onFilterData={handleFilterData}
              searchValue={search}
              // actionPagination={actionPagination}
              // setActionPagination={setActionPagination}
              // paginationSubmitButton={paginationSubmitButton}
            /> */}
          </div>
        </div>
        <Table
          data={data}
          mapData={soundCategoryMapData}
          PerPage={size}
          Page={page}
          handleSelectAll={handleSelectAll}
          selectAllChecked={selectAllChecked}
          type={"client"}
        />
        <div className="mt-3">
          <Pagination
            type={"client"}
            activePage={page}
            rowsPerPage={size}
            userTotal={videoCategoryData?.length}
            setPage={setPage}
            setData={setData}
            data={data}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoCategory;
