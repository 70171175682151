import axios from "axios";
import { setToast } from "../../../util/toast";

import { apiInstanceFetch } from "../../../util/api";
import {
  CREATE_SUB_ADMIN,
  DELETE_SUB_ADMIN,
  GET_SUB_ADMIN,
  UPDATE_SUB_ADMIN,
} from "./types";

export const getSubAdmin = () => (dispatch) => {
  apiInstanceFetch
    .get(`admin/admin/getSubAdmin`)
    .then((res) => {
      if (res.status) {
        dispatch({ type: GET_SUB_ADMIN, payload: res.data });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => {
      console.log(error);
      setToast("error", error.message);
    });
};

export const addSubAdmin = (data) => (dispatch) => {
  apiInstanceFetch
    .post(`admin/admin/roleAssignement`, data)
    .then((res) => {
      if (res.status) {
        dispatch({ type: CREATE_SUB_ADMIN, payload: res.data });
        setToast("success", "Sub Admin Insert Successful");
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};

export const deleteSubAdmin = (id) => (dispatch) => {
  apiInstanceFetch
    .delete(`admin/admin/deleteSubAdmin?adminId=${id}`)
    .then((res) => {
      if (res.status) {
        dispatch({
          type: DELETE_SUB_ADMIN,
          payload: id,
        });
        setToast("success", "Sub Admin delete Successfully");
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => {
      console.log(error);
      setToast("error", error.message);
    });
};
export const updateSubAdmin = (data, adminId) => (dispatch) => {
  axios
    .patch(`admin/admin/updateSubAdmin?adminId=${adminId}`, data)
    .then((res) => {
      
      if (res.data.status) {
        
        setToast("success", "Sub Admin Update Successfully");
        dispatch({
          type: UPDATE_SUB_ADMIN,
          payload: { data: res.data.admin, id: adminId },
        });
      } else {
        setToast("error", res.message);
      }
    });
};
