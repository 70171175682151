import { apiInstanceFetch } from "../../../util/api";
import * as ActionType from "./metrics.type";

export const getVideoMetrics =
  (startDate, endDate, start, limit, filterField, filterValue) =>
  (dispatch) => {
    apiInstanceFetch
      .get(
        `admin/videoMetric/fetchVideos?startDate=${startDate}&endDate=${endDate}&start=${start}&limit=${limit}&filterField=${filterField}&filterValue=${filterValue}`
      )
      .then((res) => {
        dispatch({
          type: ActionType.GET_VIDEO_METRICS,
          payload: {
            videoMetrics: res.data,
            totalData: res.total,
          },
        });
      })
      .catch((error) => console.error(error));
  };

export const getShortsMetrics =
  (startDate, endDate, start, limit, filterField, filterValue) =>
  (dispatch) => {
    apiInstanceFetch
      .get(
        `admin/videoMetric/fetchShorts?startDate=${startDate}&endDate=${endDate}&start=${start}&limit=${limit}&filterField=${filterField}&filterValue=${filterValue}`
      )
      .then((res) => {
        dispatch({
          type: ActionType.GET_SHORTS_METRICS,
          payload: {
            shortsMetrics: res.data,
            totalData: res.total,
          },
        });
      });
  };

export const getChannelMetrics =
  (startDate, endDate, start, limit) => (dispatch) => {
    apiInstanceFetch
      .get(
        `admin/videoMetric/fetchChannels?startDate=${startDate}&endDate=${endDate}&start=${start}&limit=${limit}`
      )
      .then((res) => {
        dispatch({
          type: ActionType.GET_CHANNEL_METRICS,
          payload: {
            channelMetrics: res.data,
            totalData: res.total,
          },
        });
      });
  };

export const getUserMetrics =
  (startDate, endDate, start, limit) => (dispatch) => {
    apiInstanceFetch
      .get(
        `admin/videoMetric/fetchUsers?startDate=${startDate}&endDate=${endDate}&start=${start}&limit=${limit}`
      )
      .then((res) => {
        dispatch({
          type: ActionType.GET_USER_METRICS,
          payload: {
            userMetrics: res.data,
            totalData: res.total,
          },
        });
      });
  };

export const getAllMetricsCount = (startDate, endDate) => (dispatch) => {
  apiInstanceFetch
    .get(
      `admin/videoMetric/videoMetricCount?startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => {
      dispatch({
        type: ActionType.GET_ALL_METRICS_COUNT,
        payload: res.dashboard,
      });
    });
};
