import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";

import Pagination from "../../extra/Pagination";
import Button from "../../extra/Button";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trashIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/EditBtn.svg";
import { deleteSubAdmin, getSubAdmin } from "../../store/subAdmin/action";
import { warning } from "../../../util/Alert";
import NewTitle from "../../extra/Title";
import Table from "../../extra/Table";
import SubAdminAdd from "../../dialogue/SubAdminAdd";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import dayjs from "dayjs";

const SubAdmin = () => {
  const { subAdmin } = useSelector((state) => state.subAdmin);
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);

  useEffect(() => {
    dispatch(getSubAdmin());
  }, []);

  useEffect(() => {
    setData(subAdmin);
  }, [subAdmin]);
  const handleOpenNew = (type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type: type,
    };
    sessionStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };
  const subAdminTable = [
    {
      Header: "NO",
      body: "name",
      Cell: ({ index }) => <span>{(page - 1) * size + index + 1}</span>,
    },

    {
      Header: "NAME",
      body: "name",
      Cell: ({ row }) => <span className="">{row?.name}</span>,
    },
    {
      Header: "EMAIL",
      body: "email",
      Cell: ({ row }) => <span className="">{row?.email}</span>,
    },
    {
      Header: "PASSWORD",
      body: "password",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.password}</span>
      ),
    },
    {
      Header: "ROLE",
      body: "role",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          <ul>
            {row?.roles?.map((detail, index) => (
              <li key={index}>{detail?.name}</li>
            ))}
          </ul>
        </span>
      ),
    },

    {
      Header: "CREATE DATE",
      body: "createdAt",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.createdAt ? dayjs(row?.createdAt).format("DD MMMM YYYY") : ""}
        </span>
      ),
    },

    {
      Header: "ACTION",
      body: "action",
      Cell: ({ row }) => (
        <div className="action-button">
          <div className="action-button">
            <Button
              btnIcon={<EditIcon />}
              onClick={() => handleEdit(row, "subAdminAdd")}
            />
          </div>
          <Button
            btnIcon={<TrashIcon />}
            onClick={() => handleDeleteSubAdmin(row)}
          />
        </div>
      ),
    },
  ];

  const handleDeleteSubAdmin = (row) => {
    const data = warning();
    data
      .then((res) => {
        if (res) {
          const yes = res.isConfirmed;
          if (yes) {
            const id = row?._id;
            dispatch(deleteSubAdmin(id));
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEdit = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type: type,
      dialogueData: row,
    };
    sessionStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };

  return (
    <div className="premium-plan  userPage withdrawal-page mb-3">
      {dialogueType == "subAdminAdd" && <SubAdminAdd />}
      <div className="dashboardHeader primeHeader mb-3 p-0">
        <NewTitle
          dayAnalyticsShow={false}
          titleShow={true}
          name={`Sub Admin`}
        />
      </div>
      <div className="payment-setting-box user-table">
        <div className="p-3">
          <div className="row">
            <div className="col-8">
              <h5
                style={{
                  fontWeight: "500",
                  fontSize: "20px",
                  marginBottom: "0px",
                  marginTop: "5px",
                  padding: "3px",
                }}
              >
                Subadmin Table
              </h5>
            </div>
            <div className="col-4 new-fake-btn d-flex justify-content-end">
              <Button
                btnIcon={<AddIcon />}
                btnName={"New"}
                newClass={"rounded"}
                onClick={() => handleOpenNew("subAdminAdd")}
              />
            </div>
          </div>
        </div>
        <div className="mt-3">
          <Table
            data={data}
            mapData={subAdminTable}
            PerPage={size}
            Page={page}
            type={"client"}
          />
          <Pagination
            type={"client"}
            activePage={page}
            rowsPerPage={size}
            userTotal={subAdmin?.length}
            setPage={setPage}
            setData={setData}
            data={data}
            actionShow={false}
          />
        </div>
      </div>
    </div>
  );
};

export default SubAdmin;
