import {
  CREATE_SUB_ADMIN,
  DELETE_SUB_ADMIN,
  GET_SUB_ADMIN,
  UPDATE_SUB_ADMIN,
} from "./types";

const initialState = {
  subAdmin: [],
  total: 0,
};

const subAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUB_ADMIN:
      return {
        ...state,
        subAdmin: action.payload,
      };
    case CREATE_SUB_ADMIN:
      
      let data = [...state.subAdmin];
      data?.unshift(action.payload);

      return {
        ...state,
        subAdmin: data,
      };

    case DELETE_SUB_ADMIN:
      return {
        ...state,
        subAdmin: state.subAdmin.filter(
          (subAdmin) => subAdmin._id !== action?.payload
        ),
      };
  case UPDATE_SUB_ADMIN:
    return {
      ...state,
      subAdmin: state.subAdmin.map((item) =>
        item?._id === action?.payload?.id ? action?.payload?.data : item
      ),
    }
    default:
      return state;
  }
};

export default subAdminReducer;
