const initialState = {
  videoMetrics: [],
  totalVideo: 0,
};

export const metricsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_VIDEO_METRICS":
      return {
        ...state,
        videoMetrics: action.payload.videoMetrics,
        totalVideo: action.payload.totalData,
      };
    case "GET_SHORTS_METRICS":
      return {
        ...state,
        shortsMetrics: action.payload.shortsMetrics,
        totalShorts: action.payload.totalData,
      };
    case "GET_CHANNEL_METRICS":
      return {
        ...state,
        channelMetrics: action.payload.channelMetrics,
        totalChannel: action.payload.totalData,
      };
    case "GET_USER_METRICS":
      return {
        ...state,
        userMetrics: action.payload.userMetrics,
        totalUser: action.payload.totalData,
      };
      case "GET_ALL_METRICS_COUNT":
        return {
          ...state,
          dashboardCount: action.payload,
        };
    default:
      return state;
  }
};
