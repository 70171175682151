import * as ActionType from "./video.type";

const initialState = {
  videoData: [],
  totalVideo: null,
  fakeUser: [],
  commentData: [],
  totalVideoComment: null,
  videoCategoryData: [],
};

export const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_VIDEO:
      return {
        ...state,
        videoData: action.payload.video,
        totalVideo: action.payload.totalVideo,
      };
    case ActionType.GET_FAKE_USER_NAME:
      return {
        ...state,
        fakeUser: action.payload,
      };

    case ActionType.IMPORT_VIDEO:
      const importedVideo = action.payload.data;

      const newVideoData = {
        ...importedVideo,
        uniqueId: importedVideo.userId.fullName,
        fullName: importedVideo.userId.fullName,
      };

      const updatedVideoDataAdd = state.videoData.unshift(newVideoData);

      return {
        ...state,
        videoData: updatedVideoDataAdd,
        totalVideo: action?.payload?.totalNumber,
      };
    case ActionType.EDIT_VIDEO:
      const updatedVideoData = state.videoData.map((item) => {
        if (item._id === action.payload.videoId) {
          return {
            ...action.payload.data,
            title: action.payload.data.title,
            fullName: action.payload.fullName,
            userId: action.payload.data?.userId?._id,
            image: action.payload.data.userId.image,
            uniqueId: action.payload.data?.uniqueVideoId,
          };
        }
        return item;
      });
      return {
        ...state,
        videoData: updatedVideoData,
      };
    case ActionType.DELETE_VIDEO:
      return {
        ...state,
        videoData: state.videoData.filter(
          (data) => !action.payload.id.includes(data._id)
        ),
      };

    case ActionType.LIVE_TOGGLE:
      console.log("action.payload", action.payload);
      const userIds = action.payload.id;
      
      return {
        ...state,
        videoData: state.videoData.map((data) => {
          if (userIds?.includes(data._id)) {
            const matchingUserData = action.payload.data.videos.find(
              (videoData) => videoData._id === data._id
            );

            console.log("matchingUserData", matchingUserData?.isVideoPublished);
            return {
              ...data,
              isVideoPublished: matchingUserData.isVideoPublished,
            };
          }
          return data;
        }),
      };

    case ActionType.COMMENT_GET:
      return {
        ...state,
        commentData: action.payload.videoComments,
        totalVideoComment: action.payload.totalVideoComment,
      };
    case ActionType.DELETE_VIDEO_COMMENTS:
      return {
        ...state,
        commentData: state.commentData.filter(
          (data) => !action.payload.id.includes(data._id)
        ),
      };

    case ActionType.GET_VIDEO_CATEGORY:
      return {
        ...state,
        videoCategoryData: action.payload.videoCategory,
      };
    case ActionType.VIDEO_CATEGORY_ADD:
      let data = [...state.videoCategoryData];
      data.unshift(action.payload);
      return {
        ...state,
        videoCategoryData: data,
      };
    case ActionType.VIDEO_CATEGORY_EDIT:
      const { id, editData } = action.payload;
      const updatedvideoCategoryData = state.videoCategoryData?.map((item) => {
        if (item?._id === id) {
          return {
            ...item,
            name: editData.name,
            image: editData.image,
          };
        }
        return item;
      });
      return {
        ...state,
        videoCategoryData: updatedvideoCategoryData,
      };

    case ActionType.VIDEO_CATEGORY_SWITCH:
      const { id1, data1 } = action.payload;
      console.log("id1", id1);
      console.log("id1", data1);

      const updatedvideoCategoryDataSwitch = state.videoCategoryData?.map(
        (item) => {
          if (item?._id === id1) {
            return {
              ...item,
              isActive: data1.isActive,
            };
          }
          return item;
        }
      );
      return {
        ...state,
        videoCategoryData: updatedvideoCategoryDataSwitch,
      };
    default:
      return state;
  }
};
