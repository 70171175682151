import React, { useState, useEffect } from "react";

function Table(props) {
  const {
    data,
    checkBoxShow,
    mapData,
    PerPage,
    Page,
    type,
    style,
    onChildValue,
    selectAllChecked,
    handleSelectAll,
  } = props;

  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [checkBox, setCheckBox] = useState();

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  // Sorting function
  const sortData = (data, sortColumn, sortOrder) => {
    return data?.sort((a, b) => {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];

      if (typeof valueA === "number" && typeof valueB === "number") {
        return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
      } else {
        return sortOrder === "asc"
          ? String(valueA).localeCompare(String(valueB))
          : String(valueB).localeCompare(String(valueA));
      }
    });
  };

  const sortedData = sortColumn
    ? sortData([...data], sortColumn, sortOrder)
    : data;

  // Slice the data for pagination
  const startIndex = (Page - 1) * PerPage;
  const endIndex = startIndex + PerPage;
  const currentPageData = sortedData && sortedData.slice(startIndex, endIndex);

  const handleColumnClick = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  return (
    <>
      <div className="primeMain table-custom">
        <table
          width="100%"
          border
          className="primeTable text-center"
          // style={{ maxHeight: "600px" }}
        >
          <thead
            className=""
            style={{ zIndex: "1", position: "sticky", top: "0" }}
          >
            <tr>
              {mapData?.map((res) => {
                return (
                  <th className="fw-bold text-nowrap">
                    <div className="table-head">
                      {res?.Header === "checkBox" ? (
                        <input
                          type="checkbox"
                          checked={selectAllChecked}
                          onChange={handleSelectAll}
                        />
                      ) : res?.sorting?.type === "client" ? (
                        <div
                          className="d-flex align-items-center"
                          onClick={() => handleColumnClick(res.body)}
                          style={{ cursor: "pointer" }}
                        >
                          {res?.Header}
                          <svg
                            className="ms-1"
                            style={{ cursor: "pointer" }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                            height={15}
                            width={15}
                          >
                            <path
                              d="M182.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L128 109.3l0 293.5L86.6 361.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l96 96c12.5 12.5 32.8 12.5 45.3 0l96-96c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 402.7l0-293.5 41.4 41.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-96-96z"
                              fill="#fff"
                            />
                          </svg>
                        </div>
                      ) : (
                        `${" "}${res?.Header}`
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>

          {type == "server" && (
            <>
              <tbody style={{ maxHeight: "600px", overflowY: "scroll" }}>
                {sortedData?.length > 0 ? (
                  <>
                    {(PerPage > 0
                      ? [sortedData].slice(
                          Page * PerPage,
                          Page * PerPage + PerPage
                        )
                      : sortedData
                    ).map((i, k) => {
                      return (
                        <>
                          <tr>
                            {mapData.map((res) => {
                              return (
                                <td>
                                  {res.Cell ? (
                                    <res.Cell row={i} index={k} />
                                  ) : (
                                    <span className={res.class}>
                                      {i[res.body]}
                                    </span>
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan="25"
                      className="text-center"
                      style={{ height: "358px", borderBottom: "none" }}
                    >
                      No Data Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </>
          )}

          {type == "client" && (
            <>
              <tbody style={{ maxHeight: "600px" }}>
                {currentPageData?.length > 0 ? (
                  <>
                    {currentPageData?.map((i, k) => {
                      return (
                        <>
                          <tr>
                            {mapData.map((res) => {
                              return (
                                <td>
                                  {res.Cell ? (
                                    <res.Cell row={i} index={k} />
                                  ) : (
                                    <span className={res.class}>
                                      {i[res.body]}
                                    </span>
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan="16"
                      className="text-center"
                      style={{ height: "358px", borderBottom: "none" }}
                    >
                      No Data Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </>
          )}
        </table>
      </div>
    </>
  );
}

export default Table;
