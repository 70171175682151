export const GET_VIDEO ="GET_VIDEO"
export const GET_FAKE_USER_NAME ="GET_FAKE_USER_NAME"
export const IMPORT_VIDEO ="IMPORT_VIDEO"
export const EDIT_VIDEO ="EDIT_VIDEO"
export const DELETE_VIDEO ="DELETE_VIDEO"
export const COMMENT_GET ="COMMENT_GET"
export const DELETE_VIDEO_COMMENTS ="DELETE_VIDEO_COMMENTS"
export const LIVE_TOGGLE ="LIVE_TOGGLE"


export const GET_VIDEO_CATEGORY="GET_VIDEO_CATEGORY"
export const VIDEO_CATEGORY_ADD="VIDEO_CATEGORY_ADD"
export const VIDEO_CATEGORY_EDIT="VIDEO_CATEGORY_EDIT"
export const VIDEO_CATEGORY_SWITCH="VIDEO_CATEGORY_SWITCH"




