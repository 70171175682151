import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../store/admin/admin.action";
import { ReactComponent as UserTotalIcon } from "../../assets/icons/UserSideBarIcon.svg";
import NewTitle from "../extra/Title";

const SubAdminDashboard = () => {
  const { admin } = useSelector((state) => state.admin);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile());
  }, []);
  return (
    <div className="dashboard " style={{ padding: "15px" }}>
      <div className="dashboardHeader primeHeader mb-3 p-0">
        <div className="row align-items-center">
          <div
            className={`
          col-12
            `}
          >
            <h4 className="heading-dashboard d-block">Welcome Sub Admin !</h4>
            <div>
              <div className="title">
                <h4
                  className="mb-0  text-capitalize text-nowrap"
                  style={{ fontSize: "17px", fontWeight: "500" }}
                >
                  Dashboard
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashBoardMain px-4 pt-2">
        <div className="row dashboard-count-box">
          <div className="row">
            {admin?.roles?.map((data) => {
              return (
                <>
                  <div className="  adminProfileBox px-2   col-lg-3 col-md-6 col-sm-12 cursor">
                    <div className="dashBoxData bg-white">
                      <div className="icon icon-sm icon-shape-small  text-center border-radius-xl my-auto icon-data1">
                        <UserTotalIcon />
                      </div>
                      <div className="dashBox-text">
                        <h5 className="text-center">{data?.name}</h5>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubAdminDashboard;
