import {
  CLOSE_LOADER,
  LOADER_OPEN,
} from "../Component/store/dialogue/dialogue.type";
import { baseURL, secretKey } from "./config";
import store from "../Component/store/Provider";
import axios from "axios";

export const openSpinner = () => {
  return store.dispatch({ type: LOADER_OPEN });
};

export const closeSpinner = () => {
  return store.dispatch({ type: CLOSE_LOADER });
};

const token = sessionStorage.getItem("token");

export const apiInstanceFetch = {
  baseURL: `${baseURL}`,
  headers: {
    "Content-Type": "application/json",
    key: `${secretKey}`,
    Authorization: `${token}`,
  },
  get: (url) => {
    openSpinner();
    return fetch(`${apiInstanceFetch?.baseURL}${url}`, {
      method: "GET",
      headers: apiInstanceFetch?.headers,
    })
      .then(handleErrors)
      .finally(() => closeSpinner());
  },

  post: (url, data) => {
    openSpinner();
    return fetch(`${apiInstanceFetch.baseURL}${url}`, {
      method: "POST",
      headers: { ...apiInstanceFetch.headers },
      body: JSON.stringify(data),
    })
      .then(handleErrors)
      .finally(() => closeSpinner());
  },

  patch: (url, data) => {
    openSpinner();
    return fetch(`${apiInstanceFetch.baseURL}${url}`, {
      method: "PATCH",
      headers: { ...apiInstanceFetch.headers },
      body: JSON.stringify(data),
    })
      .then(handleErrors)
      .finally(() => closeSpinner());
  },

  put: (url, data) => {
    openSpinner();
    return fetch(`${apiInstanceFetch.baseURL}${url}`, {
      method: "PUT",
      headers: { ...apiInstanceFetch.headers },
      body: JSON.stringify(data),
    })
      .then(handleErrors)
      .finally(() => closeSpinner());
  },

  delete: (url) => {
    openSpinner();
    return fetch(`${apiInstanceFetch.baseURL}${url}`, {
      method: "DELETE",
      headers: apiInstanceFetch.headers,
    })
      .then(handleErrors)
      .finally(() => closeSpinner());
  },
};

function handleErrors(response) {
  
  if (response.status === 403) {
    
    // Clear session and logout user on 403 error
    sessionStorage.clear();
    localStorage.clear();
    axios.defaults.headers.common["key"] = "";
    axios.defaults.headers.common["Authorization"] = "";
    window.location.href = "/";
  } 
  // else if (response.status === 500) {
    
  //   sessionStorage.clear();
  //   localStorage.clear();
  //   axios.defaults.headers.common["key"] = "";
  //   axios.defaults.headers.common["Authorization"] = "";
  //   window.location.href = "/";
  // }

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  return response.json();
}
